footer {
    padding: 20px 0 45px 0;
    min-height: 18vh;
    background-color: #efefef;
}

footer .contact img {
    margin-right: 10px;
    position: absolute;
    top: 5px;
    left: -22px;
}

footer .contact {
    word-break: break-word;
    margin-bottom: 10px;
}

footer h2 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #0a943f;
}

footer .under-line {
    margin-bottom: 12px;
    width: 79px;
    height: 2px;
    background: #0a943f;
}

.logo-footer {
    width: 60px;
    margin: 5px 0 17px 0;
}

footer a {
    color: inherit !important;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
}

footer .first-col {
    text-align: center;
}

footer .last-col {
    padding-top: 35px !important;
}

.block-contact, .block-fast-contact {
    padding-left: 50px !important;
}

@media (max-width: 992px) {
    .first-col {
        order: 0;
    }

    .block-contact {
        order: 1;
    }

    .block-fast-contact {
        order: 3;
    }

    .last-col {
        order: 2;
    }
}
