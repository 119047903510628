.profile img {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 100px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #fff;
}

.profile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.profile a#user-name {
    font-weight: bold;
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
}

.profile .dropdown-toggle::after {
    display: none;
}

.profile .dropdown-menu {
    top: 10px !important;
    animation-name: effectBlock;
    animation-duration: 0.7s;
}

.profile .dropdown-menu .dropdown-item {
    margin: 0;
    cursor: pointer;
}

.profile .dropdown-menu .dropdown-item:active {
    background: #168334;
}

.profile.dropdown .dropdown-menu {
    width: auto;
}
@keyframes effectBlock {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
