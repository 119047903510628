.form-select-language {
    width: 100%;
    position: relative;
    margin: 0 20px;
}

.form-select-language .earth-icon {
    z-index: 3;
    position: absolute;
    top: 1px;
    left: 9px;
    border-radius: 50%;
    background: #3AA965;
    width: 36px;
    height: 36px;
}

.form-select-language .earth-icon img {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 6px;
    left: 6px;
}

.form-select-language .language-select {
    width: 100%;
    cursor: pointer;
    border-radius: 52px;
    color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #0A943F;
    background-position-x: 100%;
    background-position-y: 5px;
    padding: 0 0rem 0 2.5rem;
    border: 1px solid #46a965;
    margin: 0 0 0 9px;
}

.form-select-language {
    width: 181px;
}

.form-select-language .MuiInputBase-root:before,
.form-select-language .MuiInputBase-root:after {
    display: none !important;
}

.form-select-language .MuiSelect-select:focus {
    background-color: inherit;
}

.form-select-language .MuiSelect-select {
    display: flex;
    height: 23px;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #fff;
    padding-bottom: 6px;
}

.form-select-language .MuiSelect-icon {
    color: #fff;
}

.icon-flag {
    width: 22px;
    margin-left: 10px;
}

